<template>
  <main>
    <CForm>
      <CCard>
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Report/MemberRegisterMonthly') }}</h5>
            </CCol>
            <CCol col="6" class="text-md-right">
              <CDropdown size="sm" color="danger" placement="bottom-start" class="d-inline-block">
                <template #toggler-content>
                  <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Organization.MemberAction') }}
                </template>
                <CDropdownItem @click="Print()">
                  {{ $t('Report.PrintCSV') }}
                </CDropdownItem>
              </CDropdown>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto px-2">
          <CRow class="text-nowrap">
            <CCol col="12">
              <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
                <CIcon name="cil-filter"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('CreateTime')">
                {{ $t('Organization.CreateTime') }}: {{ SearchFilter.StartTime && SearchFilter.EndTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD') + ' ~ ' + $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD') : '全部區間' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Class')">
                {{ $t('Organization.Class') }}: {{ SearchFilter.Class !== '' ? $t('Organization.ClassOptions.' + SearchFilter.Class) : '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Country')">
                {{ $t('Organization.Country') }}: {{ SearchFilter.Country || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Tags')">
                {{ $t('Organization.Tags') }}: {{ SearchFilter.Tags.join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton v-if="Features.includes('MemberLevel')" size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Level')">
                {{ $t('Organization/Member.Level') }}: {{ SearchFilter.Level.map(item => item.Name).join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('BirthdayMonth')">
                {{ $t('Organization/Member.BirthdayMonth') }}: {{ SearchFilter.BirthdayMonth ? SearchFilter.BirthdayMonth + '月' : '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('TotalRealAmount')">
                {{ $t('Organization/Member.TotalRealAmount') }}: {{ '$' + (SearchFilter.TotalRealAmountMin || 0) + ' ~ ' + (SearchFilter.TotalRealAmountMax || 'Max')}}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Sort')">
                {{ $t('Organization/Member.Sort') }}: {{ $t('Report/Member.SortType.' + this.Order) || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="danger" shape="pill" @click="ResetFilter()">
                <CIcon size="sm" name="cil-x"/>
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto">
          <div class="mx-auto">
            <CChartLine
              :datasets="ChartDatasets"
              :labels="ChartLabels"
              :options="ChartOptions"
            />
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
                  id="MemberRegisterDailyList"
                  :items="Reporting"
                  :fields="Field"
                  :loading="Loading"
                  :noItemsView="noItemsView"
                  addTableClasses="small text-nowrap"
                  responsive
                  hover
          >
          </CDataTable>
        </CCardBody>
      </CCard>
    </CForm>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Email'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Email') }}
        </CCol>
        <CCol sm="9">
          <multiselect
                  id="SearchFilter.Email"
                  v-model="SearchFilter.Email"
                  :optionHeight="24"
                  :tag-placeholder="$t('Global.EnterToAdd')"
                  :placeholder="''"
                  :deselectLabel="$t('Global.DeselectLabel')"
                  :selectedLabel="$t('Global.SelectedLabel')"
                  :options="SearchFilter.Email"
                  :multiple="true"
                  :taggable="true"
                  @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Class'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Class') }}
        </CCol>
        <CCol sm="9">
          <CSelect :options="ClassOptions" v-model="SearchFilter.Class" :value.sync="SearchFilter.Class" :placeholder="$t('Global.PleaseSelect')"/>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Country'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Country') }}
        </CCol>
        <CCol sm="9">
          <CSelect :options="CountryOptions" v-model="SearchFilter.Country" :value.sync="SearchFilter.Country" :placeholder="$t('Global.PleaseSelect')"/>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Tags'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Tags') }}
        </CCol>
        <CCol sm="9">
          <multiselect
                  id="SearchFilter.Tags"
                  v-model="SearchFilter.Tags"
                  :optionHeight="24"
                  :tag-placeholder="$t('Global.EnterToAdd')"
                  :placeholder="''"
                  :deselectLabel="$t('Global.DeselectLabel')"
                  :selectedLabel="$t('Global.SelectedLabel')"
                  :options="Tags"
                  :multiple="true"
                  :taggable="true"
                  @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="(ShowFilter === 'All' || ShowFilter === 'Level') && Features.includes('MemberLevel')" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.Level') }}
        </CCol>
        <CCol sm="9">
          <multiselect
                  id="SearchFilter.Level"
                  v-model="SearchFilter.Level"
                  :optionHeight="24"
                  :tag-placeholder="$t('Global.EnterToAdd')"
                  :placeholder="''"
                  label="Name"
                  track-by="ID"
                  :deselectLabel="$t('Global.DeselectLabel')"
                  :selectedLabel="$t('Global.SelectedLabel')"
                  :options="MemberLevelList"
                  :multiple="true"
                  :taggable="true"
                  @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'BirthdayMonth'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.BirthdayMonth') }}
        </CCol>
        <CCol sm="9">
          <CSelect :options="BirthDayMonthOptions" v-model="SearchFilter.BirthdayMonth" :value.sync="SearchFilter.BirthdayMonth" :placeholder="$t('Global.PleaseSelect')"/>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'TotalRealAmount'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.TotalRealAmount') }}
        </CCol>
        <CCol sm="9">
          <CInput placeholder="請輸入消費金額最小值" v-model="SearchFilter.TotalRealAmountMin">
            <template #prepend-content>
              $
            </template>
          </CInput>
          <CInput placeholder="請輸入消費金額最大值" v-model="SearchFilter.TotalRealAmountMax">
            <template #prepend-content>
              $
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'CreateTime'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.CreateTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.TimeRange" is-range @input="SearchFilter.StartTime = SearchFilter.TimeRange.start;SearchFilter.EndTime = SearchFilter.TimeRange.end">
            <template v-slot="{ inputValue, inputEvents }">
              <CRow>
                <CCol col="12">
                  <CInput :placeholder="$t('Logistics.DeliverySettings.SelectStartDate')" :value="inputValue.start" v-on="inputEvents.start">
                    <template #append-content>
                      <CIcon name="cil-calendar"/>
                    </template>
                  </CInput>
                </CCol>
                <CCol col="12">
                  <CInput :placeholder="$t('Logistics.DeliverySettings.SelectEndDate')" :value="inputValue.end" v-on="inputEvents.end">
                    <template #append-content>
                      <CIcon name="cil-calendar"/>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.Sort') }}
        </CCol>
        <CCol sm="9">
          <CInputRadioGroup v-model="Order" :options="SortOption" :checked.sync="Order" :custom="true" inline/>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
                :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="GetList()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
  {
  "meta": {
  "label": "每月註冊總表"
  }
  }
</route>

<script>
import {CChartLine} from '@coreui/vue-chartjs'

export default {
  name: 'MemberRegisterDaily',
  layout: 'manage',
  components: {
    CChartLine,
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Reporting: [],
      MemberLevelList: [],
      Tags: [],
      SearchFilter: {
        Email: [],
        Class: '',
        Name: '',
        Country: '',
        Mobile: '',
        Tags: [],
        Level: [],
        TotalRealAmountMin: '',
        TotalRealAmountMax: '',
        WalletBonusMin: '',
        WalletBonusMax: '',
        BirthdayMonth: '',
        StartTime: this.$dayjs().subtract(2, 'month').startOf('month').unix() * 1000,
        EndTime: this.$dayjs().endOf('month').unix() * 1000,
        TimeRange: {
          start: this.$dayjs().subtract(2, 'month').unix() * 1000,
          end: this.$dayjs().unix() * 1000
        },
        City: '',
        Area: '',
        Address: ''
      },
      Features: [],
      NotifyFeatures: [],
      FilterModel: false,
      ShowFilter: 'All',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Organization/Member.NoResults'),
        noItems: this.$t('Organization/Member.NoItems')
      },
      Order: 'CreateTime_desc',
      ChartOptions: {
        responsive: true,
        aspectRatio: 4
      }
    }
  },
  computed: {
    ChartLabels() {
      return this.Reporting.map(item => item.CreateTime).sort((a, b) => this.$dayjs(a).unix() - this.$dayjs(b).unix())
    },
    ChartDatasets() {
      if (this.Reporting.length > 0) {
        const SortReporting = this.Reporting.map(item => item).sort((a, b) => this.$dayjs(a.CreateTime).unix() - this.$dayjs(b.CreateTime).unix())
        const TotalRegisterArray = SortReporting.map(item => item.MemberCount)
        const MaleRegisterArray = SortReporting.map(item => item.MaleCount)
        const FemaleRegisterArray = SortReporting.map(item => item.FemaleCount)
        const UncertainRegisterArray = SortReporting.map(item => item.UncertainCount)
        return [
          {
            label: this.$t('Report/Member.MemberCount'),
            borderColor: '#4184f3',
            fill: false,
            data: TotalRegisterArray
          },
          {
            label: this.$t('Report/Member.MaleCount'),
            borderColor: '#7cb442',
            fill: false,
            data: MaleRegisterArray
          },
          {
            label: this.$t('Report/Member.FemaleCount'),
            borderColor: '#ff5722',
            fill: false,
            data: FemaleRegisterArray
          },
          {
            label: this.$t('Report/Member.UncertainCount'),
            borderColor: '#ff8f00',
            fill: false,
            data: UncertainRegisterArray
          }
        ]
      } else {
        return []
      }
    },
    ClassOptions() {
      return Object.keys(this.$t('Organization.ClassOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Organization.ClassOptions')[option]
        }
      })
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    SortOption() {
      return Object.keys(this.$t('Report/Member.SortType')).map(option => {
        return {
          value: option,
          label: this.$t('Report/Member.SortType')[option]
        }
      })
    },
    Field() {
      return [
        {key: 'CreateTime', label: this.$t('Report/Member.RegisterTime')},
        {key: 'MemberCount', label: this.$t('Report/Member.MemberCount')},
        {key: 'MaleCount', label: this.$t('Report/Member.MaleCount')},
        {key: 'FemaleCount', label: this.$t('Report/Member.FemaleCount')},
        {key: 'UncertainCount', label: this.$t('Report/Member.UncertainCount')}
      ]
    },
    BirthDayMonthOptions() {
      const options = []
      for (let i = 1; i <= 12; i++) {
        options.push(i.toString().padStart(2, '0'))
      }
      return options
    }
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Organization ? (PermissionSession.Features.Organization.Member || []) : []
    this.NotifyFeatures = PermissionSession.Features.Notify || []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  watch: {
    '$route': function (newValue) {
      if (newValue.query.createUser === 'true') {
        this.Action = 'Create'
        this.CreateUserModel = true
      }
    },
  },
  methods: {
    Init() {
      const Executes = [
        this.GetAllTags(),
        this.GetList()
      ]
      if (this.Features.includes('MemberLevel')) {
        Executes.push(
          this.GetMemberLevelList()
        )
      }
      return Promise.all(Executes).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList($state) {
      this.ChooseUsers = []
      this.FilterModel = false
      this.Loading = true
      const data = {
        Order: this.Order || 'nto'
      }
      if ($state) {
        data.Page++
      }
      const FilterData = ['Class', 'StartTime', 'EndTime', 'City', 'TotalRealAmountMax', 'TotalRealAmountMin', 'Country', 'Level', 'Tags', 'BirthdayMonth']
      FilterData.forEach((field) => {
        switch (typeof this.SearchFilter[field]) {
          case 'object':
            if (Array.isArray(this.SearchFilter[field]) && this.SearchFilter[field].length > 0) {
              switch (field) {
                case 'Level':
                  data['Level.ID'] = this.SearchFilter[field].map(item => item.ID)
                  break
                default:
                  data[field] = this.SearchFilter[field]
                  break
              }
            } else {
              switch (field) {
                case 'StartTime':
                  data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
              }
            }
            break
          default:
            if (this.SearchFilter[field]) {
              switch (field) {
                case 'StartTime':
                  data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
                default:
                  data[field] = this.SearchFilter[field]
                  break
              }
            }
            break
        }
      })
      if (parseInt(this.SearchFilter.TotalRealAmountMin) > parseInt(this.SearchFilter.TotalRealAmountMax)) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': 消費金額最小值不可大於最大值',
          type: 'error'
        })
        this.Loading = false
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/member/register/month',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
          ...data
        },
        headers: {
          'id-token': localStorage.getItem('XX-CSRF-Token')
        }
      }).then(({data}) => {
        this.Reporting = data.Data
        this.Loading = false
      }).catch((err) => {
        if ($state) {
          $state.complete()
        }
        this.Loading = false
        throw err
      })
    },
    ResetState(closeCreateUserModel = false) {
      if (closeCreateUserModel) {
        this.CreateUserModel = false
      }
      this.Submit = false
      this.AlertModel = false
      this.ChooseUsers = []
    },
    SetZip() {
      this.UserData.ZipCode = parseInt(this.District.filter(Data => Data.name === this.UserData.City)[0].districts.filter(Area => Area.name === this.UserData.Area)[0].zip)
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.Pagination = {
        Page: 1,
        PerPage: 30,
        TotalItems: 0,
        TotalPages: 0
      }
      this.SearchFilter = {
        Email: [],
        Class: '',
        Name: '',
        Country: '',
        Mobile: '',
        Tags: [],
        TotalRealAmountMin: '',
        TotalRealAmountMax: '',
        WalletBonusMin: '',
        WalletBonusMax: '',
        BirthdayMonth: '',
        Level: [],
        StartTime: this.$dayjs().subtract(2, 'month').startOf('month').unix() * 1000,
        EndTime: this.$dayjs().endOf('month').unix() * 1000,
        TimeRange: {
          start: this.$dayjs().subtract(2, 'month').unix() * 1000,
          end: this.$dayjs().unix() * 1000
        },
        City: '',
        Area: '',
        Address: ''
      }
      this.ChooseIndex = []
      this.Init()
    },
    GetMemberLevelList() {
      this.Loading = true
      this.MemberLevelList = [
        {
          ID: 0,
          Name: '一般會員',
          ShopDiscount: 100,
          GoldDiscount: 0,
          FullAmount: 0,
          NextFullAmount: '--',
          Status: true
        }
      ]
      return this.$store.dispatch('InnerRequest', {
        url: '/user/level/list',
        method: 'post'
      }).then(({data}) => {
        this.Loading = false
        Object.values(data).forEach((item) => {
          this.MemberLevelList.push(item)
        })
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        throw err
      })
    },
    GetAllTags() {
      this.Submit = true
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/member/tags',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
        },
        headers: {
          'id-token': localStorage.getItem('XX-CSRF-Token')
        }
      }).then(({data}) => {
        this.Loading = false
        this.Submit = false
        this.Tags = data.Data
      }).catch((error) => {
        this.Loading = false
        this.Submit = false
        throw error
      })
    },
    Print() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        Order: this.Order || 'nto'
      }

      const FilterData = ['Class', 'StartTime', 'EndTime', 'City', 'TotalRealAmountMax', 'TotalRealAmountMin', 'Country', 'Level', 'Tags', 'BirthdayMonth']
      FilterData.forEach((field) => {
        switch (typeof this.SearchFilter[field]) {
          case 'object':
            if (Array.isArray(this.SearchFilter[field]) && this.SearchFilter[field].length > 0) {
              switch (field) {
                case 'Level':
                  Data['Level.ID'] = this.SearchFilter[field].map(item => item.ID)
                  break
                default:
                  Data[field] = this.SearchFilter[field]
                  break
              }
            } else {
              switch (field) {
                case 'StartTime':
                  Data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  Data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
              }
            }
            break
          default:
            if (this.SearchFilter[field]) {
              switch (field) {
                case 'StartTime':
                  Data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  Data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
                default:
                  Data[field] = this.SearchFilter[field]
                  break
              }
            }
            break
        }
      })
      if (parseInt(this.SearchFilter.TotalRealAmountMin) > parseInt(this.SearchFilter.TotalRealAmountMax)) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': 消費金額最小值不可大於最大值',
          type: 'error'
        })
        this.Loading = false
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/service/exportReportCSV',
        method: 'post',
        data: {
          URL: '/member/register/month',
          Data,
          type: 'memberRegister'
        }
      }).then((response) => {
        console.log(response)
        this.Loading = false
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `每月註冊總表${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    }
  }
}
</script>
